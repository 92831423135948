import { addMinutes, endOfDay, startOfDay } from "date-fns";

export function generateTimeRange(
  date: string | number | Date | null = Date.now(),
  minuteStep = 30
) {
  if (date === null || date === undefined) {
    date = Date.now();
  } else if (typeof date === "string") {
    date = new Date(date).getTime();
  } else if (date instanceof Date) {
    date = date.getTime();
  }
  const timeRange: Date[] = [];
  const start = startOfDay(new Date(date));
  const end = endOfDay(new Date(start));
  while (start.getTime() < end.getTime()) {
    const currentMinute = start.getMinutes();
    const next = start.setMinutes(currentMinute + minuteStep);
    timeRange.push(addMinutes(new Date(next), -minuteStep));
  }
  return timeRange;
}

export function scheduleTick(cb: () => void, ms = 0) {
  const x = setTimeout(() => {
    cb();
    clearTimeout(x);
  }, ms);
}
